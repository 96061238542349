<template>
	<div class="Login">
		<v-main>
			<div class="formContent">
				<v-form ref="formLogin">
					<v-text-field outlined label="Usuário" prepend-inner-icon="person" v-model="user" :rules="inputValidateUser"></v-text-field>
					<v-text-field outlined label="Senha" prepend-inner-icon="fingerprint" v-model="pass" :type="'password'" :rules="inputValidatePass"></v-text-field>

					<v-row justify="space-around">
						<v-btn depressed dark rounded color="indigo darken-4" justify="space-around" class="my-3" @click="submit" :loading="loadingStatus">
							<span>Acessar</span>
							<v-icon right>touch_app</v-icon>
						</v-btn>
					</v-row>
				</v-form>
			</div>

			<v-snackbar v-model="snackbarShower" :timeout="2000" top :color="snackbarColor">
				<v-icon>verified_user</v-icon> {{snackbarText}}
				<template v-slot:action="{ attrs }">
					<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
						<v-icon small>close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>
	</div>
</template>
<script>
export default {
	data() {
		return {
			user: '',
			pass: '',
			loadingStatus: false,
			inputValidateUser: [
				v => v.length != '' || 'Digite um usuário válido'
			],
			inputValidatePass: [
				v => v.length != '' || 'Digite a senha'
			],
			snackbarColor: 'green',
			snackbarText: 'Login efetuado com sucesso!',
			snackbarShower: false
		}
	},
	methods: {
		submit() {
      		if (this.$refs.formLogin.validate()) {
      			this.loadingStatus = true;

      			var thisEl = this;
      			setTimeout(function(){
	      			thisEl.$http.post(
	      				'login/do_login',
	      				{
                            user: thisEl.user,
                            pass: thisEl.pass
                        },
                        { emulateJSON: true }
                    ).then(
						function(r){//sucesso
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Login efetuado com sucesso!';
                            	thisEl.snackbarShower = true;

                            	//guardando dados de sessao na memoria
                            	localStorage.setItem('logado', r.body.userdata.logado);
                            	localStorage.setItem('token', r.body.token);
								localStorage.setItem('id_usuario', r.body.userdata.id_usuario);
								localStorage.setItem('nome_usuario', r.body.userdata.nome_usuario);
								localStorage.setItem('id_cargo_usuario', r.body.userdata.id_cargo_usuario);
								localStorage.setItem('cargo_usuario', r.body.userdata.cargo_usuario);
								localStorage.setItem('cpf', r.body.userdata.cpf);
								localStorage.setItem('login', thisEl.user);
								
								
                            	setTimeout(function(){
                            		thisEl.$router.push('/');
                            	}, 2000);
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
    					},
                        function(error){//erro na requisição, por exemplo 404
                            thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = 'Houve um erro com a requisição.';
		      				thisEl.snackbarShower = true;
                        }
					)
				}, 1000);
      		}
      	}
    },
	mounted() {
    	// if (window.location.hostname != 'localhost' && window.location.protocol == 'http:') {
		// 	window.location = 'https://www.sisgroup.app.br';
		// }
	}
};
</script>